import React, { useState, useEffect } from "react";
 
import "../css/pageIntro.css";
import introvide from "../video/TalentMarketplace.mp4";
import arrow from "../images/icon-arrow.png";
// import PageIntroVideo from "../video/PageIntroVideo.mp4"


export default function PageIntro() {
  const [toggle, setToggle] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const handleVideo = ({ target }) => {
    if (target.classList.contains("show")) {
      setToggle(false);
      document.querySelector("video").pause();
    } else {
      target.pause();
    }
  };

  const scrollToSection = (section) => {
    setActiveSection(section);
    const element = document.getElementById(section);
    const offset = element.offsetTop;
    const navbarHeight = 60;
    window.scrollTo({ top: offset - navbarHeight, behavior: "smooth" });
  };
 
  return (
    <>
      <div className="page-intro-container"id="home">

        <div className="text-container">

          <h1 className="intro-title">Brunel <span className="talent">Talent</span> Marketplace</h1>
          <p className="Slogan">
          Unleashing Potential through Expert-Driven IT Consulting
          </p>
          <div className="intro-text"> 

          We are a <span className="talent">non-profit</span> division at Brunel University dedicated to empowering <span className="talent">SMEs and Startups</span> through our expertise in IT solutions. 
          Our mission is to deliver IT services at <span className="talent">affordable prices</span> guided by experts and fuelled by talented students.
          </div>
        </div>


          <div className="items-container">
            {/* <div className="image-container">
            </div> */}
            <button className="GetInTouch" onClick={()=>{window.location.href="mailto:btm@brunel.ac.uk?subject=BTM Query"}}> Get In Touch</button>
          </div>
          <div className="arrow-container">
         {/* <a data-scroll href="#services"> */}
          <div className="intro-arrow" onClick={()=>{scrollToSection("services")}}>
          </div>
          {/* </a> */}
          </div>
        {/* <img src={arrow} alt="arrow to scroll down" className="intro-arrow"/> */}
          </div>
      
    </>
  );
}