import {BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

import LoginPage from "./pages/LoginPage";
import "./App.css";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import TalentView from "./pages/TalentView";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-PG2CL90ELK')
ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

export default function App() {
  return (
    <>
        <BrowserRouter >
            <Routes>
                
            <Route path="/" element={<Home />} />
                {/* <Route path="/loginpage" element={<LoginPage />} />    
                <Route path="/signup" element={<SignUp />} />     
                <Route path="/talentview" element={<TalentView />} />    */}
            </Routes>
        </BrowserRouter>
      
    </>
  );
}

